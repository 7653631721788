import {
  external,
  importInternal,
  CircleRoiTool,
  getModule,
  toolColors,
  toolStyle,
  getToolState,
} from 'cornerstone-tools';

// State
// import { getToolState } from './../../stateManagement/toolState.js';
// import toolStyle from './../../stateManagement/toolStyle.js';
// import toolColors from './../../stateManagement/toolColors.js';
// import { getModule } from '../../store/index';

// Drawing
// import {
//   getNewContext,
//   draw,
//   setShadow,
//   drawCircle,
//   drawHandles,
//   drawLinkedTextBox,
// } from './../../drawing/index.js';

// Util
// import getROITextBoxCoords from '../../util/getROITextBoxCoords.js';
// import numbersWithCommas from './../../util/numbersWithCommas.js';
// import getPixelSpacing from '../../util/getPixelSpacing';
// import getCircleCoords from '../../util/getCircleCoords';

// const BaseTool = importInternal('base/BaseTool');
// const Cursors = importInternal('tools/cursors');

const getNewContext = importInternal('drawing/getNewContext');
const draw = importInternal('drawing/draw');
const setShadow = importInternal('drawing/setShadow');
const drawCircle = importInternal('drawing/drawCircle');
const drawHandles = importInternal('drawing/drawHandles');
const drawLinkedTextBox = importInternal('drawing/drawLinkedTextBox');

const getROITextBoxCoords = importInternal('util/getROITextBoxCoords');
const numbersWithCommas = importInternal('util/numbersWithCommas');
const getPixelSpacing = importInternal('util/getPixelSpacing');
const throttle = importInternal('util/throttle');
const calculateSUV = importInternal('util/calculateSUV');
const ellipseUtils = importInternal('util/ellipseUtils');
// const getCircleCoords = importInternal('util/getCircleCoords');

export default class MyCircleRoiTool extends CircleRoiTool {
  constructor(props) {
    if (props) {
      props.configuration.drawHandlesOnHover = false;
    }
    super(props);

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  updateCachedStats(image, element, data) {
    const seriesModule =
      external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};
    const modality = seriesModule.modality;
    const pixelSpacing = getPixelSpacing(image);

    const stats = _calculateStats(
      image,
      element,
      data.handles,
      modality,
      pixelSpacing
    );

    data.cachedStats = stats;
    data.invalidated = false;
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      console.error(
        `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      );

      return;
    }

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        middle: {
          x: eventData.currentPoints.image.x - 25,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        end: {
          x: eventData.currentPoints.image.x - 50,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        initialRotation: eventData.viewport.rotation,
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  renderToolData(evt) {
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const getDistance = external.cornerstoneMath.point.distance;
    const eventData = evt.detail;
    const { image, element, canvasContext } = eventData;
    const lineWidth = toolStyle.getToolWidth();
    const {
      handleRadius,
      drawHandlesOnHover,
      renderDashed,
    } = this.configuration;
    const newContext = getNewContext(canvasContext.canvas);
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);
    const lineDash = getModule('globalConfiguration').configuration.lineDash;

    // Meta
    const seriesModule =
      external.cornerstone.metaData.get('generalSeriesModule', image.imageId) ||
      {};

    // Pixel Spacing
    const modality = seriesModule.modality;
    const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

    draw(newContext, context => {
      // If we have tool data for this element, iterate over each set and draw it
      for (let i = 0; i < toolData.data.length; i++) {
        const data = toolData.data[i];

        if (data.visible === false) {
          continue;
        }

        // Configure
        const color = toolColors.getColorIfActive(data);
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
        };

        setShadow(context, this.configuration);

        const { start, middle, end } = data.handles;

        const startCanvas = external.cornerstone.pixelToCanvas(element, start);

        // Calculating the radius where startCanvas is the center of the circle to be drawn
        const center = findCenterPoint(start, middle, end);
        const centerCanvas = external.cornerstone.pixelToCanvas(
          element,
          center
        );
        const radius = getDistance(startCanvas, centerCanvas);

        const circleOptions = { color };

        if (renderDashed) {
          circleOptions.lineDash = lineDash;
        }

        // Draw Circle
        drawCircle(
          context,
          element,
          // data.handles.start,
          center,
          radius,
          // radius * 2,
          // radius,
          circleOptions,
          'pixel'
        );

        // data.handles.middle = {
        //   x: data.handles.end.x / 2,
        //   y: data.handles.end.y / 2,
        //   highlight: true,
        //   active: false,
        //   hasMoved: true,
        // };

        drawHandles(context, eventData, data.handles, handleOptions);

        // const middleDot = {
        //   x: center.x,
        //   y: center.y,
        //   highlight: false,
        //   active: false,
        // };

        drawCircle(context, element, center, 1, circleOptions, 'pixel');

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.cachedStats) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Default to textbox on right side of ROI
        if (!data.handles.textBox.hasMoved) {
          const defaultCoords = getROITextBoxCoords(
            eventData.viewport,
            data.handles
          );

          Object.assign(data.handles.textBox, defaultCoords);
        }

        const textBoxAnchorPoints = handles =>
          _findTextBoxAnchorPoints(handles.start, handles.middle, handles.end);

        const textBoxContent = _createTextBoxContent(
          context,
          image.color,
          data.cachedStats,
          modality,
          hasPixelSpacing,
          this.configuration
        );

        data.unit = _getUnit(modality, this.configuration.showHounsfieldUnits);

        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          textBoxContent,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          10,
          true
        );
      }
    });
  }
}

function _getUnit(modality, showHounsfieldUnits) {
  return modality === 'CT' && showHounsfieldUnits !== false ? 'HU' : '';
}

function _createTextBoxContent(
  context,
  isColorImage,
  { area, mean, stdDev, min, max, meanStdDevSUV } = {},
  modality,
  hasPixelSpacing,
  options = {}
) {
  const showMinMax = options.showMinMax || false;
  const textLines = [];

  // Don't display mean/standardDev for color images
  const otherLines = [];

  if (!isColorImage) {
    const hasStandardUptakeValues = meanStdDevSUV && meanStdDevSUV.mean !== 0;
    const unit = _getUnit(modality, options.showHounsfieldUnits);

    let meanString = `Mean: ${numbersWithCommas(mean.toFixed(2))} ${unit}`;
    const stdDevString = `Std Dev: ${numbersWithCommas(
      stdDev.toFixed(2)
    )} ${unit}`;

    // If this image has SUV values to display, concatenate them to the text line
    if (hasStandardUptakeValues) {
      const SUVtext = ' SUV: ';

      const meanSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.mean.toFixed(2)
      )}`;
      const stdDevSuvString = `${SUVtext}${numbersWithCommas(
        meanStdDevSUV.stdDev.toFixed(2)
      )}`;

      const targetStringLength = Math.floor(
        context.measureText(`${stdDevString}     `).width
      );

      while (context.measureText(meanString).width < targetStringLength) {
        meanString += ' ';
      }

      otherLines.push(`${meanString}${meanSuvString}`);
      otherLines.push(`${stdDevString}`);
      otherLines.push(`${stdDevSuvString}`);
    } else {
      otherLines.push(`${meanString}`);
      otherLines.push(`${stdDevString}`);
    }

    if (showMinMax) {
      let minString = `Min: ${min} ${unit}`;
      const maxString = `Max: ${max} ${unit}`;
      const targetStringLength = hasStandardUptakeValues
        ? Math.floor(context.measureText(`${stdDevString}     `).width)
        : Math.floor(context.measureText(`${meanString}     `).width);

      while (context.measureText(minString).width < targetStringLength) {
        minString += ' ';
      }

      otherLines.push(`${minString}${maxString}`);
    }
  }

  textLines.push(_formatArea(area, hasPixelSpacing));
  otherLines.forEach(x => textLines.push(x));

  return textLines;
}

function _formatArea(area, hasPixelSpacing) {
  // This uses Char code 178 for a superscript 2
  const suffix = hasPixelSpacing
    ? ` mm${String.fromCharCode(178)}`
    : ` px${String.fromCharCode(178)}`;

  return `Area: ${numbersWithCommas(area.toFixed(2))}${suffix}`;
}

function _findTextBoxAnchorPoints(startHandle, middleHandle, endHandle) {
  const { left, top, width, height } = getCircleCoords(
    startHandle,
    middleHandle,
    endHandle
  );

  return [
    {
      // Top middle point of ellipse
      x: left + width / 2,
      y: top,
    },
    {
      // Left middle point of ellipse
      x: left,
      y: top + height / 2,
    },
    {
      // Bottom middle point of ellipse
      x: left + width / 2,
      y: top + height,
    },
    {
      // Right middle point of ellipse
      x: left + width,
      y: top + height / 2,
    },
  ];
}

function findCenterPoint(p1, p2, p3) {
  const offset = Math.pow(p2.x, 2) + Math.pow(p2.y, 2);
  const bc = (Math.pow(p1.x, 2) + Math.pow(p1.y, 2) - offset) / 2.0;
  const cd = (offset - Math.pow(p3.x, 2) - Math.pow(p3.y, 2)) / 2.0;
  const det = (p1.x - p2.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p2.y);

  // if (Math.abs(det) < TOL) { throw new IllegalArgumentException("Yeah, lazy."); }

  const idet = 1 / det;

  const centerx = (bc * (p2.y - p3.y) - cd * (p1.y - p2.y)) * idet;
  const centery = (cd * (p1.x - p2.x) - bc * (p2.x - p3.x)) * idet;
  // const radius = Math.sqrt(
  //   Math.pow(p2.x - centerx, 2) + Math.pow(p2.y - centery, 2)
  // );

  const center = { x: centerx, y: centery };
  return center;
}

function getCircleCoords(startHandle, middleHandle, endHandle) {
  const center = findCenterPoint(startHandle, middleHandle, endHandle);

  // const startCanvas = external.cornerstone.pixelToCanvas(element, startHandle);

  // // Calculating the radius where startCanvas is the center of the circle to be drawn
  // const centerCanvas = external.cornerstone.pixelToCanvas(element, center);
  const getDistance = external.cornerstoneMath.point.distance;
  const radius = getDistance(startHandle, center);

  return {
    left: Math.floor(Math.min(center.x - radius, center.x)),
    top: Math.floor(Math.min(center.y - radius, center.y)),
    width: radius * 2,
    height: radius * 2,
  };
}

function _calculateStats(image, element, handles, modality, pixelSpacing) {
  // Retrieve the bounds of the ellipse in image coordinates
  const circleCoordinates = getCircleCoords(
    handles.start,
    handles.middle,
    handles.end
  );

  // Retrieve the array of pixels that the ellipse bounds cover
  const pixels = external.cornerstone.getPixels(
    element,
    circleCoordinates.left,
    circleCoordinates.top,
    circleCoordinates.width,
    circleCoordinates.height
  );

  // Calculate the mean & standard deviation from the pixels and the ellipse details.
  const ellipseMeanStdDev = ellipseUtils.calculateEllipseStatistics(
    pixels,
    circleCoordinates
  );

  let meanStdDevSUV;

  if (modality === 'PT') {
    meanStdDevSUV = {
      mean: calculateSUV(image, ellipseMeanStdDev.mean, true) || 0,
      stdDev: calculateSUV(image, ellipseMeanStdDev.stdDev, true) || 0,
    };
  }

  const area =
    Math.PI *
    ((circleCoordinates.width * (pixelSpacing.colPixelSpacing || 1)) / 2) *
    ((circleCoordinates.height * (pixelSpacing.rowPixelSpacing || 1)) / 2);

  return {
    area: area || 0,
    count: ellipseMeanStdDev.count || 0,
    mean: ellipseMeanStdDev.mean || 0,
    variance: ellipseMeanStdDev.variance || 0,
    stdDev: ellipseMeanStdDev.stdDev || 0,
    min: ellipseMeanStdDev.min || 0,
    max: ellipseMeanStdDev.max || 0,
    meanStdDevSUV,
  };
}

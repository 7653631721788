import React from 'react';
import { asyncComponent, retryImport } from '@ohif/ui';

import commandsModule from './commandsModule.js';
import toolbarModule from './toolbarModule.js';
import withCommandsManager from './withCommandsManager.js';
import { version } from '../package.json';
// This feels weird
// import loadLocales from './loadLocales';

const OHIFVTKViewport = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "OHIFVTKViewport" */ './OHIFVTKViewport.js')
  )
);

const OHIFVRViewport = asyncComponent(() =>
  retryImport(() =>
    import(/* webpackChunkName: "OHIFVRViewport" */ './OHIFVRViewport.js')
  )
);

// const OHIFVRViewport = () => {
//   return (
//     <div className="row">
//       <div className="col-xs-12 col-sm-6">
//         <View3D
//           volumes={this.state.volumeRenderingVolumes}
//           onCreated={this.saveApiReference}
//         />
//       </div>
//     </div>
//   );
// };

const vtkExtension = {
  /**
   * Only required property. Should be a unique value across all extensions.
   */
  id: 'vtk',
  version,

  getViewportModule({ commandsManager, servicesManager }) {
    const ExtendedVTKViewport = props => (
      <OHIFVTKViewport
        {...props}
        servicesManager={servicesManager}
        commandsManager={commandsManager}
      />
    );
    // const ExtendedVRViewport = props => (
    //   <OHIFVRViewport
    //     {...props}
    //     servicesManager={servicesManager}
    //     commandsManager={commandsManager}
    //   />
    // );
    return withCommandsManager(ExtendedVTKViewport, commandsManager);
  },
  getToolbarModule() {
    return toolbarModule;
  },
  getCommandsModule({ commandsManager, servicesManager }) {
    return commandsModule({ commandsManager, servicesManager });
  },
};

export default vtkExtension;

export { vtkExtension };

// loadLocales();
